import "isomorphic-fetch";
import * as React from "react";
import App from "next/app";

import "../src/themes/tachyons.css";
import "../src/themes/fonts.css";
import "../src/themes/fonts2.css";
import "../src/themes/overrides.css";
import "../src/themes/browserOverrides.css";
import "../src/themes/effects.css";
import "../src/themes/customClasses.css";
import "../src/themes/toggle.css";
import "../src/themes/light.css";


export default class extends App {

  public render() {
    const { props } = this as any;
    const { Component, pageProps } = props;

    return (
        <>
          <Component {...pageProps} />
        </>
    );
  }
}
